<template>
  <div id="stepper-mobile" v-if="steps.length > 0 && currentMarker.index != undefined" class='mobile'>
    <div>
      <v-progress-linear
      v-model="timeBuffer"
      color="primary"
      height="8"
      reactive
      ></v-progress-linear>
    </div>

    <div class="video-header d-flex flex-row align-center justify-space-between px-1" v-if="currentMarker.index > 0 && isPlaying === false">

      <div class="col-6 pa-1 d-flexflex-column align-start justify-start" v-if="isPlaying === false">
        <template v-if="currentMarker.index > 0 && isPlaying === false">
          <div class="">
            <v-btn small color="primary" class="ma-0" @click="clickStep(steps[currentMarker.index - 1])">
              <v-icon>replay</v-icon>
            </v-btn>
          </div>
            <div class="smallify text-left text-truncate"  style="max-width:94%">
              <strong class="text text-truncate" style="max-width:90%">{{steps[currentMarker.index-1].title  || `Step ${parseFloat(currentMarker.index-1)}`}}</strong>
            </div>

        </template>
      </div>

      <v-divider inset vertical v-if="isPlaying === false" class="mb-2"></v-divider>

      <div class="col-6 pa-1" v-if="isPlaying === false">
        <!-- <template v-if="currentMarker.index == 0 && isPlaying === false">
          <div class="d-flex flex-column align-end justify-start">
            <div class="">
              <v-btn small class="ma-0 gradient" color="secondary" @click="clickStep(steps[0], 'start')">
                <v-icon>play_arrow</v-icon>
              </v-btn>
            </div>
            <div class="smallify" @click="clickStep(steps[0], 'start')">
              <strong class="text ">Start Video</strong>
            </div>
          </div>
        </template> -->
        <template v-if="currentMarker.index > 0 && isPlaying === false">
          <div class="d-flex flex-column align-end justify-start">
            <div class="">
              <v-btn small class="ma-0" color="secondary" @click="clickStep(steps[currentMarker.index], 'start')">
                <v-icon>play_arrow</v-icon>
              </v-btn>
            </div>
            <div class="smallify text-right text-truncate"  style="max-width:94%">
              <strong class="text" >{{steps[currentMarker.index].title || `Step ${parseFloat(currentMarker.index)}`}}</strong>
            </div>
          </div>
        </template>
      </div>

    </div>


      <v-btn v-if="skipIntro && isPlaying && currentMarker.index == 0"
        color="white"
        class="skip-intro"
        @click="setSkipIntro">
        Skip Intro <v-icon>skip_next</v-icon>
      </v-btn>


  </div>
</template>

<script>


export default {
  name: "stepper",
  props: ['steps', 'currentMarker', 'isPlaying', 'skipIntro', 'timeIn', 'duration'],
  data(){
    return{
      avatarSize: 36,
      currentIndex: 0,
      timeBuffer: 0,
      durationTotal: 0,
      stepTotal: 0,
    }
  },
  watch:{
    timeIn:function(newV,oldV){
      if(newV.timeupdate !== undefined){
        // // process.env.NODE_ENV === "development" ? console.log( 'timeIn', newV.timeupdate, oldV.timeupdate, this.time ) : null;
        // let cleanSeconds =  Math.floor(newV.timeupdate|0);
        // let total = 0;
        // let percent = 0;
        // process.env.NODE_ENV === "development" ? console.log( this.currentMarker.index+1 < this.steps.length) : null;
        // if(this.currentMarker.index+1 < this.steps.length){
        //   total = this.steps[this.currentMarker.index+1].timestamp - this.steps[this.currentMarker.index].timestamp;
        //   // console.log('total', cleanSeconds, total, (((cleanSeconds-this.steps[this.currentMarker.index+1].timestamp) + total) / total)*100 );
        //   percent = (((cleanSeconds-this.steps[this.currentMarker.index+1].timestamp) + total) / total)*100
        // }
        // else{
        //   // percent = ((cleanSeconds)/(this.durationTotal))*100
        //   total = this.durationTotal - this.steps[this.currentMarker.index].timestamp;
        //   percent = (((cleanSeconds-this.durationTotal) + total) / total)*100
        //   console.log('total', cleanSeconds, this.durationTotal, total, (((cleanSeconds-this.durationTotal) + total) / total)*100);
        // }
        let remainingTime = this.currentMarker.index+1 < this.steps.length ? this.steps[this.currentMarker.index+1].timestamp : this.duration;
        // $stepPercentLeft(currentIndex, stepLength, seconds, currentTimestamp, nextTimestamp, durationTotal)
        let percent = this.$stepPercentLeft(this.currentMarker.index,this.steps.length,newV.timeupdate,this.steps[this.currentMarker.index].timestamp, remainingTime ,this.duration);
        this.timeBuffer = this.steps[this.currentMarker.index].timestamp <= this.currentMarker.step.time ? percent :100
      }
    },
    // currentMarker:{
    //   handler: function (val, oldVal) {
    //     process.env.NODE_ENV === "development" ? console.log( 'val from video', val ) : null;
    //     if(this.currentMarker.index+1 < this.steps.length){
    //       this.stepTotal = this.steps[this.currentMarker.index+1].timestamp;
    //     }
    //   },
    //   deep: true
    // }
  },
  methods: {
    setIndex(index){
      this.currentIndex = index;
      return index
    },
    setSkipIntro(){
      this.$emit('skipIntro', true);
    },
    clickStep(marker, start){
      let data = []
      data.element = false;
      data.step = marker;
      data.start = start != undefined ? true : false;
      this.$emit('onStepClicked', data)
    },
    startPlaying(){
      this.$emit('startPlaying', true)
    }
  },
  mounted(){
    this.durationTotal = this.$moment.duration(this.duration).asSeconds()
  }
};

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
%gradientBG{
  background: #FEAC5E!important;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #4BC0C8, #C779D0, #FEAC5E)!important;  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #4BC0C8, #C779D0, #FEAC5E)!important; /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

#stepper-mobile{
  position: relative;
  .video-header{
    // height: 65px;
    background: rgba(187,187,187, .3);
    .label{
      opacity: .5;
      font-size: .8rem;
    }
    .text{
      line-height: 1rem;
    }
  }

  header{
    .layout{padding: 5px;}
    .stats{
      margin-right: 10px;
      font-size: 20px;
      font-weight: bold;
      color: white;
      .step-number{
        font-size: 150%;
      }
      .slash{
        opacity: .5;
        padding: 0 2px;
      }
      .step-total{
        opacity: .75;
        font-size: 75%;
      }
    }
  }

  .smallify{
    // transform: scale(.75,.75);
    margin-top: 4px;
    font-size: 11px;
  }

  .v-progress-linear__background{
    background-color: #bbb!important;
  }
  .v-progress-linear__determinate{
    @extend %gradientBG;
  }

  .skip-intro{
    position: absolute;
    z-index: 9999999;
    top:-90px;
    right: 5%;
  }

  .order-first{
    order:0;
  }
  .order-last{
    order: 10;
  }
  .wizard{
    padding: 10px;
    .v-card{
      padding: 10px;
    }
    .v-avatar{
      margin: 0 8px 5px 5px;
      &:hover{
        cursor: pointer;
      }
    }
  }

}
</style>
